<template>
  <div
    class="
      min-h-screen
      flex flex-col flex-auto flex-shrink-0
      antialiased
      bg-white
      dark:bg-gray-700
      text-gray-800
      dark:text-white
    "
  >
    <Header />
    <Sidebar />
    <div class="h-full ml-14 mt-14 mb-10 md:ml-64 p-4">
      <div class="mb-2 border border-blue-800 w-50" v-if="tenders">
        <h3 class="p-2" @click="showInner = !showInner">CPV's</h3>

        <div class="p-2" v-show="showInner || cpv">
          <CpvTree
            @click="add"
            @dblclick="add_recursive"
            :deletedItem="deletedItem"
          />
          <CpvSelected
            class="cpv-selected"
            :added="added"
            :showSubscribeButton="cpv"
            @delete="deleteItem"
            v-show="CPVSelected"
            :showSubscribed="showSubscribed"
          />
        </div>
      </div>
      <CpvMainPanel
        :showSubscribed="true"
        :showSubscribeButton="cpv"
        class="mt-4"
        v-if="cpv"
      />
      <AdvancedSearch @submit="submitFilter" v-if="tenders" />
      <!-- <Table /> -->
      <router-view
        v-if="!cpv"
        :filterData="filterData"
        :cpvIds="cpvIds"
        :login="login"
        :isCPV="cpv"
        @login="logged_in"
      ></router-view>
    </div>
  </div>
</template>

<script>
import CpvMainPanel from "./components/CpvMainPanel.vue";
import AdvancedSearch from "../src/components/AdvancedSearch.vue";
import router from "../src/router/router.js";
import TAjax from "./mixins/TAjax.vue";
import Cookies from "./mixins/Cookies.js";
import CpvTree from "./components/CpvTree.vue";
import CpvSelected from "./components/CpvSelected.vue";
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  data() {
    return {
      filterData: {},
      login: false,
      showInner: false,
      deletedItem: 0,
      added: [],
      resetBtn: false,
      showSubscribed: false,
      cpvIds: [],
    };
  },
  components: {
    Header,
    Sidebar,
    Footer,
    AdvancedSearch,
    CpvTree,
    CpvSelected,
    CpvMainPanel,
  },
  mixins: [TAjax],
  watch: {
    "$route.path": function () {
      if (this.cpv && !this.login) {
        this.$router.replace("/login");
      }
    },
  },
  methods: {
    add(data) {
      if (!this.containsObject(data, this.added)) {
        this.added.push(data);
        this.cpvIds = this.added.map((dt) => dt.cpvId);
      }
    },
    add_recursive(data) {
      this.added.push(data);
      let parent = data.parent;
      // console.log(this.added);

      this.added.map((c) => {
        // console.log(`${c.code} == ${parent}`);
        if ("children" in c == false) {
          if (c.code == parent) c.recursive = true;
          else if (c.recursive == false) c.recursive = false;
        }
      });

      // console.log("this.added");
      // console.log(this.added);
    },
    containsObject(obj, list) {
      var i;
      obj = JSON.stringify(obj);
      for (i = 0; i < list.length; i++) {
        let listitem = JSON.stringify(list[i]);
        if (listitem == obj) {
          return true;
        }
      }

      return false;
    },
    deleteItem(code) {
      this.deletedItem = code;
      this.added = this.added.filter((c) => {
        if (
          ("code" in c && c.code != code) ||
          ("parent" in c && c.parent != code)
        ) {
          return true;
        }
      });
      // console.log(code);
    },
    triggerSearch() {
      this.$emit("submit", this.added);
      this.resetBtn = true;
    },
    resetSearch() {
      this.resetBtn = false;
      this.$emit("submit", {});
    },
    submitFilter(data) {
      this.filterData = JSON.parse(JSON.stringify(data));
    },
    gotoLogin() {
      return router.push({ path: this.buttonRoute });
    },
    logged_in() {
      this.login = true;
    },
    async logout() {
      let cookie_obj = Cookies.getCookieObject();

      this.writeCookie("access_token", "", -1);
      this.writeCookie("refresh_token", "", -1);
      this.writeCookie("logintype", "", -1);
      if (cookie_obj.logintype == "google") {
        this.writeCookie("sub", "", -1);
        localStorage.removeItem("familyName");
        localStorage.removeItem("givenName");
        localStorage.removeItem("email");
      }
      this.login = false;

      if (cookie_obj.logintype == "keycloak") {
        const params = {
          refresh_token: cookie_obj.refresh_token,
          access_token: cookie_obj.access_token,
        };
        await this.postData("http://localhost:1111/logout", params);
        // const data = result.data;
      }

      router.push({ path: "/" });
    },
    writeCookie(cookieName, cookieValue, cookieExpireIn) {
      let cookie =
        `${cookieName}=${cookieValue}; max-age=` + cookieExpireIn + ";path=/;";
      return (document.cookie = cookie);
    },
    async checkAndUpdateToken() {
      let cookie_obj = Cookies.getCookieObject();
      // NOTE: refresh-token is for single use!
      if (cookie_obj.access_token === undefined) {
        if (cookie_obj.refresh_token !== undefined) {
          // console.log("Getting new Access-Token...");
          const params = { refresh_token: cookie_obj.refresh_token };
          const result = await this.postData(
            "http://localhost:1111/token",
            params
          );
          const data = result.data;

          if (!data.error) {
            this.writeCookie(
              "access_token",
              data.access_token,
              data.expires_in
            );
            this.writeCookie(
              "refresh_token",
              data.refresh_token,
              data.refresh_expires_in
            );
          }
        }
      }
    },
  },
  computed: {
    CPVSelected() {
      return this.added.length > 0 ? true : false;
    },
    tenders() {
      return this.$route.path == "/tenders" ? true : false;
    },
    cpv() {
      return this.$route.path == "/cpvsubscribe" ? true : false;
    },
    buttonTxt() {
      return this.login == true ? "User Account" : "Login";
    },
    buttonRoute() {
      return this.login == true ? "/user" : "/login";
    },
  },
  async created() {
    let cookie_obj = Cookies.getCookieObject();

    // provisorical checking for login - when entering a site that need the actual
    // tokens, we check them with keycloak, github or google
    if (cookie_obj.access_token || cookie_obj.refresh_token) {
      this.login = true;

      // If logged in with Keycloak
      if (cookie_obj.logintype == "keycloak") {
        this.login = true;
        setInterval(() => {
          // console.log("Checking for new Token...");
          this.checkAndUpdateToken();
        }, 10000);
      }
    }

    // if logged in with Github
    if (cookie_obj.logintype == "github" && !cookie_obj.access_token) {
      let URL_params = new URLSearchParams(window.location.search.substring(1));

      const params = {
        code: URL_params.get("code"),
      };

      const result = await this.postData(
        "http://localhost:1111/github",
        params
      );
      const data = result.data;

      if (data.access_token) {
        this.writeCookie("access_token", data.access_token, 8 * 60 * 60);
        this.login = true;
      }
    }
  },
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.head.appendChild(googleScript);
  },
};
</script>

<style>
html body {
  --black: #414a4c;
  --white: #fffff8;
  --gray: #e9e9e9;
  font-size: 16px !important;
  /* font-family: "et-book", serif; */
  background: var(--white);
}
input:focus {
  outline: none;
}
input {
  padding: 0.15rem;
}
</style>

<style scoped>
div.cpv-filter {
  border: 1px solid black;
  /* padding: 1rem; */
  margin-bottom: 1rem;
}
div.cpv-filter h3.head {
  padding: 0.5rem;
  cursor: pointer;
}

div.cpv-filter h3.head:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

div.cpv-filter .inner {
  margin: 0 0 1rem 0;
  padding: 0.5rem 1rem;
}
.cpv-selected {
  margin-top: 1rem;
}
</style>
