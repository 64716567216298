<script>
import Cookies from "./Cookies.js";
import TAjax from "./TAjax.vue";

export default {
  name: "User",
  mixins: [TAjax],
  methods: {
    async loadUserKeycloak() {
      // console.log("fetching user (Keycloak)...");
      let cookie_obj = Cookies.getCookieObject();

      let params = {
        access_token: cookie_obj.access_token,
      };

      const result = await this.postData(
        "http://localhost:1111/user-info",
        params
      );

      const data = result.data;

      return data;
    },
    async loadUserGithub() {
      // console.log("fetching user (Github)...");
      let cookie_obj = Cookies.getCookieObject();

      if (!cookie_obj.access_token) return false;

      let params = {
        access_token: cookie_obj.access_token,
      };

      const result = await this.postData(
        "http://localhost:1111/github-user-info",
        params
      );
      const data = result.data;

      return data;
    },
    loadUserGoogle() {
      // console.log("fetching user (Google)...");
      let cookie_obj = Cookies.getCookieObject();

      if (cookie_obj.access_token) {
        var base64Url = cookie_obj.access_token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const JWT_decoded = JSON.parse(jsonPayload);

        const data = {
          email: JWT_decoded.email,
          familyName: JWT_decoded.family_name,
          givenName: JWT_decoded.given_name,
        };

        return data;
      }

      return false;
    },
    async loadUser() {
      let cookie_obj = Cookies.getCookieObject();
      let data;

      if (cookie_obj.logintype == "keycloak") {
        data = await this.loadUserKeycloak();
      } else if (cookie_obj.logintype == "github") {
        data = await this.loadUserGithub();
        if (data) {
          data.preferred_username = data.login;
        }
      } else if (cookie_obj.logintype == "google") {
        data = this.loadUserGoogle();
        if (data) {
          data.given_name = data.givenName;
          data.family_name = data.familyName;
        }
      }

      return data ? data : false;
    },
  },
};
</script>
