<template>
  <!-- Header -->
  <div
    class="fixed w-full flex items-center justify-between h-14 text-white z-10"
  >
    <div
      class="
        flex
        items-center
        justify-start
        pl-3
        w-14
        md:w-64
        h-14
        bg-blue-800
        dark:bg-gray-800
        border-none
      "
    >
      <span v-if="login" class="hidden md:block">
        <span v-if="getCookieObj().logintype != 'google'">
          <span>{{ userdata.preferred_username }}&nbsp;</span>
          &nbsp;
        </span>
        <span v-if="getCookieObj().logintype != 'github'">
          <span>{{ userdata.given_name }}&nbsp;</span>
          &nbsp;
        </span>
        <span v-if="getCookieObj().logintype != 'github'">
          <span>{{ userdata.family_name }}&nbsp;</span>
          &nbsp;
        </span>
      </span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        h-14
        bg-blue-800
        dark:bg-gray-800
        header-right
      "
    >
      <div></div>
      <ul class="flex items-center">
        <li>
          <a
            role="button"
            @click="gotoLogin"
            class="flex items-center mr-4 hover:text-blue-100"
          >
            <span class="inline-flex mr-1">
              <svg
                v-if="login"
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                ></path>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="w-5 h-5"
              >
                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                <polyline points="10 17 15 12 10 7" />
                <line x1="15" y1="12" x2="3" y2="12" />
              </svg>
            </span>
            {{ buttonTxt }}
          </a>
        </li>
        <li>
          <div class="block w-px h-6 mx-3 bg-gray-400 dark:bg-gray-700"></div>
        </li>
        <li v-if="login">
          <a
            @click="logout"
            role="button"
            class="flex items-center mr-4 hover:text-blue-100"
          >
            <span class="inline-flex mr-1">
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </span>
            Logout
          </a>
        </li>
        <li v-else-if="!login">
          <router-link
            class="flex items-center mr-4 hover:text-blue-100"
            to="/register"
          >
            Register
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <!-- ./Header -->
</template>
<script>
import router from "../../src/router/router.js";
import Cookies from "../mixins/Cookies.js";
import User from "../mixins/User.vue";
export default {
  mixins: [Cookies, User],
  data() {
    return {
      login: false,
      userdata: {},
    };
  },
  computed: {
    email() {
      return this.userdata.email ? this.userdata.email : "-";
    },
    buttonTxt() {
      return this.login == true ? "User Account" : "Login";
    },
    buttonRoute() {
      return this.login == true ? "/user" : "/login";
    },
  },
  async created() {
    let cookie_obj = Cookies.getCookieObject();

    // provisorical checking for login - when entering a site that need the actual
    // tokens, we check them with keycloak, github or google
    if (cookie_obj.access_token || cookie_obj.refresh_token) {
      this.login = true;

      // If logged in with Keycloak
      if (cookie_obj.logintype == "keycloak") {
        this.login = true;
        setInterval(() => {
          console.log("Checking for new Token...");
          this.checkAndUpdateToken();
        }, 10000);
      }
    }

    // if logged in with Github
    if (cookie_obj.logintype == "github" && !cookie_obj.access_token) {
      let URL_params = new URLSearchParams(window.location.search.substring(1));

      const params = {
        code: URL_params.get("code"),
      };

      const result = await this.postData(
        "http://localhost:1111/github",
        params
      );
      const data = result.data;

      if (data.access_token) {
        this.writeCookie("access_token", data.access_token, 8 * 60 * 60);
        this.login = true;
      }
    }
    let data = await this.loadUser();

    // console.log("data");
    // console.log(data);

    if (!data) this.$router.push("/");

    this.userdata = data;
  },
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.head.appendChild(googleScript);
  },
  methods: {
    getCookieObj() {
      return Cookies.getCookieObject();
    },
    gotoLogin() {
      return router.push({ path: this.buttonRoute });
    },
    async logout() {
      let cookie_obj = Cookies.getCookieObject();

      this.writeCookie("access_token", "", -1);
      this.writeCookie("refresh_token", "", -1);
      this.writeCookie("logintype", "", -1);
      if (cookie_obj.logintype == "google") {
        this.writeCookie("sub", "", -1);
        localStorage.removeItem("familyName");
        localStorage.removeItem("givenName");
        localStorage.removeItem("email");
      }
      this.login = false;

      if (cookie_obj.logintype == "keycloak") {
        const params = {
          refresh_token: cookie_obj.refresh_token,
          access_token: cookie_obj.access_token,
        };
        await this.postData("http://localhost:1111/logout", params);
        // const data = result.data;
      }

      router.push({ path: "/" });
    },
    writeCookie(cookieName, cookieValue, cookieExpireIn) {
      let cookie =
        `${cookieName}=${cookieValue}; max-age=` + cookieExpireIn + ";path=/;";
      return (document.cookie = cookie);
    },
    async checkAndUpdateToken() {
      let cookie_obj = Cookies.getCookieObject();
      // NOTE: refresh-token is for single use!
      if (cookie_obj.access_token === undefined) {
        if (cookie_obj.refresh_token !== undefined) {
          // console.log("Getting new Access-Token...");
          const params = { refresh_token: cookie_obj.refresh_token };
          const result = await this.postData(
            "http://localhost:1111/token",
            params
          );
          const data = result.data;

          if (!data.error) {
            this.writeCookie(
              "access_token",
              data.access_token,
              data.expires_in
            );
            this.writeCookie(
              "refresh_token",
              data.refresh_token,
              data.refresh_expires_in
            );
          }
        }
      }
    },
  },
};
</script>
<style scoped>
/* Custom style */
.header-right {
  width: calc(100% - 3.5rem);
}
.sidebar:hover {
  width: 16rem;
}
@media only screen and (min-width: 768px) {
  .header-right {
    width: calc(100% - 16rem);
  }
}
</style>