<template>
  <div>
    <ul v-if="subscribedCpvs.length">
      <li
        class="cursor-pointer"
        v-for="item in subscribedCpvs"
        :key="item.cpvId"
        :class="item.recursive ? 'bold' : ''"
        @click="removeItem(item.cpvId, item.recursive)"
      >
        {{ item.label }}
      </li>
    </ul>
    <p v-else>No CPV's subscribed yet.</p>
  </div>
</template>

<script>
import TAjax from "../mixins/TAjax.vue";
import Cookies from "../mixins/Cookies.js";
import User from "../mixins/User.vue";

export default {
  name: "CpvSelected",
  props: ["email", "load"],
  mixins: [TAjax, Cookies, User],
  watch: {
    load() {
      this.getSubscribed();
      this.$emit("loading", false);
    },
  },
  data() {
    return {
      subscribedCpvs: [],
    };
  },
  methods: {
    async getSubscribed() {
      // console.log("getting Subscribed...");

      let userdata = await this.loadUser();

      const postObj = {
        email: userdata.email,
        type: "cpv",
      };

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/subscribed/`,
        postObj
      );

      const data = result.data;

      // console.log(data);

      // for (let i = 0; i < data.length; i++) {
      //   const element = data[i];
      // console.log(element.tenderId);
      // }

      this.subscribedCpvs = data;

      // console.log(this.subscribedCpvs);
    },
    async removeItem(id, recursive) {
      let userdata = await this.loadUser();

      const postObj = {
        id: id,
        email: userdata.email,
        recursive: recursive,
      };

      /* const result = */ await this.postData(
        "http://localhost:4000/cpvfilter/deletecpv",
        postObj
      );

      // console.log(result);

      this.getSubscribed();
    },
  },
  computed: {},
  created() {
    this.getSubscribed();
  },
};
</script>

<style scoped>
div {
  padding: 0.5rem;
}
.bold {
  font-weight: bold;
}
li:hover {
  text-decoration: line-through;
}
</style>
