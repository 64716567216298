export default {
  getCookieObject() {
    // Turn Cookies into key/value pairs
    let arr =
      document.cookie.split(";").length > 1
        ? document.cookie.split(";")
        : [document.cookie];
    let cookie_obj = {};
    if (arr.length >= 1) {
      cookie_obj = Object.fromEntries(
        arr.map((c) => {
          return c.split("=").map((item) => {
            return item.trim();
          });
        })
      );
    }
    return cookie_obj;
  },
};
