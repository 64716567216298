<template>
  <div class="login">
    <h1 class="text-3xl font-bold mb-2">Login</h1>
    <InputField
      @change="onchange"
      :fieldName="'Username'"
      :inputName="'username'"
      :inputPlaceholder="'Username'"
    />
    <InputField
      @change="onchange"
      :fieldName="'Password'"
      :inputName="'password'"
      :inputPlaceholder="'Password'"
      :inputType="'password'"
    />
    <div
      class="
        flex-initial
        bg-blue-800
        text-white
        font-semibold
        flex
        items-center
        justify-center
        py-2
        px-4
        mr-4
        mt-4
        w-64
        cursor-pointer
      "
      @click="login"
    >
      Sign In
    </div>
    <div
      class="
        flex-initial
        bg-blue-800
        text-white
        font-semibold
        flex
        items-center
        justify-center
        py-2
        px-4
        mr-4
        mt-4
        w-64
        cursor-pointer
      "
      @click="loginGitHub"
    >
      Sign In with GitHub
    </div>
    <div class="mt-4" id="google_sign_in"></div>
    <div class="mt-5 text-green-600" v-if="success">You're logged in!</div>
    <div class="mt-5 text-red-600" v-else-if="error">Error: {{ error }}</div>
  </div>
</template>

<script>
import InputField from "../components/InputField.vue";
import TAjax from "../mixins/TAjax.vue";
import router from "../router/router.js";

export default {
  name: "Login",
  components: {
    InputField,
  },
  mixins: [TAjax],
  data() {
    return {
      inputData: {
        username: "",
        password: "",
      },
      error: "",
      success: false,
    };
  },
  methods: {
    onchange(data) {
      this.inputData[data.field] = data.value;
      // console.log(this.inputData);
    },
    async login() {
      if (this.success == false) {
        const result = await this.postData(
          "http://localhost:1111/login",
          this.inputData
        );
        const data = result.data;

        if (!data.error) {
          this.writeCookie("access_token", data.access_token, data.expires_in);
          this.writeCookie(
            "refresh_token",
            data.refresh_token,
            data.refresh_expires_in
          );

          this.writeCookie("logintype", "keycloak", 80 * 60 * 60);
          this.success = true;

          //delay
          await this.delay(1000);

          this.$emit("login", true);
          router.push("/");
        } else {
          this.error = data.error_description;
        }
      }
    },
    loginGitHub() {
      // https://github.com/login/oauth/authorize?client_id=<client-id>&redirect_uri=<redirect_uri>&scope=<scope>
      const baseURL = "https://github.com/login/oauth/authorize?";
      const client_id = "client_id=629d29004da9634b6bd8";
      const redirect_uri = "&redirect_uri=http://localhost:8080";
      const scope = "&scope=read:user";

      this.writeCookie("logintype", "github", 80 * 60 * 60);

      window.location.href = baseURL + client_id + redirect_uri + scope;
    },
    writeCookie(cookieName, cookieValue, cookieExpireIn) {
      let cookie =
        `${cookieName}=${cookieValue}; max-age=` + cookieExpireIn + ";path=/;";
      return (document.cookie = cookie);
    },
    convertDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const d = new Date(dateString);
      const year = d.getFullYear();
      const date = d.getDate();

      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];

      const dayIndex = d.getDay();
      const dayName = days[dayIndex];

      const hours = d.getHours();
      const minutes = d.getMinutes();
      const seconds = d.getSeconds();

      // 2022-05-23T18:21:00.000Z
      // Mon, 23 May 2022 18:33:00 GMT
      return `${dayName}, ${date} ${monthName} ${year} ${hours}:${minutes}:${seconds} GMT`;
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    async handleCredentialResponse(response) {
      if (response.credential) {
        var base64Url = response.credential.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const JWT_decoded = JSON.parse(jsonPayload);

        // localStorage.setItem("email", JWT_decoded.email);
        // localStorage.setItem("familyName", JWT_decoded.family_name);
        // localStorage.setItem("givenName", JWT_decoded.given_name);
        this.writeCookie("sub", JWT_decoded.sub, 80 * 60 * 60);
        this.writeCookie("access_token", response.credential);
        this.writeCookie("logintype", "google", 80 * 60 * 60);

        this.success = true;

        //delay
        await this.delay(1000);

        this.$emit("login", true);
        router.push("/");
      }
    },
  },
  mounted() {
    const google = window.google;

    google.accounts.id.initialize({
      client_id:
        "499023110157-ovipu68rvrr7j7c1pvgve4lo6sl9qbbf.apps.googleusercontent.com",
      callback: this.handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById("google_sign_in"),
      { theme: "outline", size: "large" } // customization attributes
    );
  },
};
</script>

<style></style>
