<template>
  <div>
    <a
      @click="$router.go(-1)"
      class="
        cursor-pointer
        block
        bg-blue-800
        text-white
        font-semibold
        py-2
        px-4
        mb-4
        w-max
      "
      >Back to Details
    </a>
    <vue-good-table
      ref="table"
      @on-selected-rows-change="rowSelected"
      :columns="columns"
      :rows="rows"
      :fixed-header="true"
      max-height="75vh"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-search="onSearch"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="onRowClickDocs"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :search-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
      }"
      :select-options="{
        enabled: toggleSelect,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 50,
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: true,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
    >
      <div slot="selected-row-actions">
        <span class="selections-info align-bottom">
          {{ selections.length }} rows selected.
        </span>
        <span class="btn btn-sm btn-warning mx-2" @click="clearSelections"
          >Clear</span
        >
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import VueGoodTableRemote from "../mixins/VueGoodTableRemote.vue";
import TAjax from "../mixins/TAjax.vue";
import "../assets/tailwind.css";

export default {
  name: "documentsTender",
  mixins: [VueGoodTableRemote, TAjax],
  components: {
    VueGoodTable,
  },
  data() {
    return {
      toggleSelect: false,
      selections: [],
      searchTerm: "",
      isLoading: false,
      totalRecords: 0,
      vgtServerParams: {
        pages: {
          page: 1,
          size: 50,
        },
        sort: {
          sortBy: "Id",
          sortOrder: "asc",
        },
      },
      columns: [
        {
          label: "Addendum ID",
          field: "addendumId",
          thClass: "align-bottom",
        },
        {
          label: "Title",
          field: "title",
          thClass: "align-bottom",
        },
        {
          label: "File",
          field: "file",
          thClass: "align-bottom",
        },
        {
          label: "Description",
          field: "description",
          thClass: "align-bottom",
        },
        {
          label: "Language",
          field: "lang",
        },
      ],
      rows: [],
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "";

      let dateArr = dateString.split("T"); // array(0 => yyyy-mm-dd, 1 => hh:mm:ss:SSSSZ)

      dateArr = dateArr[0];

      dateArr = dateArr.split("-");

      // console.log(dateArr);

      return dateArr[0] + "/" + dateArr[1] + "/" + dateArr[2];
    },
    async loadTenderDocuments() {
      // console.log("loadTenderDocuments()");
      this.isLoading = true;
      const tenderId = this.$route.params.id;
      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/tenders/:id/docs`,
        {
          id: tenderId,
        }
      );
      const data = result.data;
      this.totalRecords = data.count;

      this.rows = data.rows;
    },
  },
  created() {
    this.loadTenderDocuments();
  },
};
</script>

<style></style>
