export default {
  data() {
    return {
      debounceTimer: null,
    };
  },
  methods: {
    pgtDebounce(func, delay) {
      const context = this;

      const args = arguments;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => func.apply(context, args), delay);
    },
  },
};
