<template>
  <div>
    <InputField
      @change="onchange"
      :inputName="'searchCPV'"
      :inputPlaceholder="'Search CPV'"
    />
    <ul>
      <TreeItem
        :depth="0"
        v-for="items in cpvObject"
        :key="items.code + items.id"
        :label="items.description"
        :code="items.code"
        :cpvId="items.id"
        :deletedItem="deletedItem"
        @click="add"
        @dblclick="add_recursive"
      />
    </ul>
  </div>
</template>

<script>
import InputField from "../components/InputField.vue";
import TAjax from "../mixins/TAjax.vue";
import TreeItem from "./TreeItem.vue";

export default {
  name: "CpvTree",
  mixins: [TAjax],
  components: { TreeItem, InputField },
  props: ["deletedItem"],
  data() {
    return {
      inputData: {},
      cpvObject: [],
    };
  },
  methods: {
    onchange(data) {
      this.inputData[data.field] = data.value;
      this.initCpvs(true);
      // console.log(this.inputData);
    },
    async initCpvs(refreshAll = false) {
      // console.log("initCpvs()");
      if (refreshAll) {
        this.cpvObject = [];
      }
      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/cpvs/:parent`,
        this.inputData
      );
      let data = result.data;

      data.map((c) => {
        this.cpvObject.push(c);
      });
    },
    add(data) {
      // emit to Main-Panel
      this.$emit("click", data);
    },
    add_recursive(data) {
      // emit to Main-Panel
      // console.log("CpvTree.vue");
      // console.log(data);
      this.$emit("dblclick", data);
    },
  },
  created() {
    this.initCpvs();
  },
};
</script>

<style scoped>
div {
  /* border: 1px solid black; */
  /* padding: 1rem; */
}
</style>
