<template>
  <div>
    <div class="flex">
      <div>
        <input
          type="checkbox"
          name="includeChilds"
          id="includeChilds"
          v-model="includeChilds"
        />
        <label class="ml-1 cursor-pointer" for="includeChilds">
          Include Sub-Categories
        </label>
      </div>
      <span
        class="
          ml-3
          bg-blue-800
          text-white
          font-semibold
          flex
          items-center
          justify-center
          py-1
          px-2
          mr-4
          cursor-pointer
        "
        v-show="search"
        @click="refresh"
        >Refresh</span
      >
    </div>
    <div class="mt-2 flex">
      <input
        type="text"
        v-model="usertext"
        @input="getMatchingCpvs"
        placeholder="CPV-Code"
      />
      <div class="ml-3 selected" v-if="selected_desc" @click="removeSelection">
        {{ selected_desc }}
      </div>
    </div>
    <div v-if="cpvs.length > 0" class="ajax-items">
      <ul>
        <li
          v-for="cpv in cpvs"
          :key="cpv.code + cpv.description"
          @click="addToSelection(cpv.code, cpv.description)"
        >
          {{ cpv.description }}
        </li>
      </ul>
    </div>

    <div
      class="mt-5"
      style="max-width: 1100px"
      v-show="chart.data.datasets[0].data.length > 0"
    >
      <!-- <Bar
        :chart-options="chartOptions"
        :chart-data="data"
      /> -->
      <canvas id="chart"></canvas>
    </div>
  </div>
</template>

<script>
import TAjax from "../mixins/TAjax.vue";
// import { Bar } from "vue-chartjs/legacy";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

export default {
  mixins: [TAjax],
  data() {
    return {
      usertext: "",
      includeChilds: false,
      cpvs: [],
      selected_id: 0,
      selected_desc: "",
      search: false,

      chart: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              type: "bar",
              label: "Submitted Tenders",
              data: [],
              backgroundColor: ["rgb(245, 68, 7, 0.2)"],
              borderColor: ["rgb(245, 68, 7)"],
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Total Quantity of Tenders",
              data: [],
              backgroundColor: "rgba(54,73,93,.5)",
              borderColor: "#36495d",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
      chartOb: {},
    };
  },
  methods: {
    async getMatchingCpvs() {
      if (this.usertext != "") {
        let params = {
          usertext: this.usertext,
        };

        const result = await this.postData(
          "http://localhost:4000/cpv/search",
          params
        );

        const data = result.data;

        // console.log(data);

        this.cpvs = data;
      } else {
        this.cpvs = [];
      }
    },
    async addToSelection(id, desc) {
      this.selected_id = id;
      this.selected_desc = desc;

      this.refresh();
    },
    async refresh() {
      // Destroy previous Chart
      if (this.chartObj instanceof ChartJS) {
        this.destroyChart();
      }

      // Get data for Chart
      await this.getQuantityOfTenders();
      await this.getFrequencyOfIssuing();

      // Build Chart
      this.buildChart();

      this.cpvs = [];
      this.usertext = "";
      this.search = true;
    },
    async getFrequencyOfIssuing() {
      let params = {
        id: this.selected_id,
        includeAllChilds: this.includeChilds,
      };

      const result = await this.postData(
        "http://localhost:4000/cpv/issuing-frequency",
        params
      );

      const data = result.data;

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      this.chart.data.labels = [];
      this.chart.data.datasets[0].data = [];

      // Fill data for graph
      Object.keys(data).forEach((key) => {
        this.chart.data.labels.push(monthNames[key.replace("0", "")]);
        this.chart.data.datasets[0].data.push(data[key]);
      });

      return data;
    },
    async getQuantityOfTenders() {
      let params = {
        id: this.selected_id,
        includeAllChilds: this.includeChilds,
      };

      const result = await this.postData(
        "http://localhost:4000/cpv/quantity-tenders",
        params
      );

      const data = result.data;

      this.chart.data.datasets[1].data = [];

      // Fill data for graph
      Object.keys(data).forEach((key) => {
        this.chart.data.datasets[1].data.push(data[key]);
      });

      // console.log("getQuantityOfTenders");
      // console.log(data);

      return data;
    },
    removeSelection() {
      this.selected_id = 0;
      this.selected_desc = "";
      this.chart.data.labels = [];
      this.chart.data.datasets[0].data = [];
      this.search = false;
    },
    buildChart() {
      const ctx = document.getElementById("chart");
      this.chartObj = new ChartJS(ctx, this.chart);
    },
    destroyChart() {
      this.chartObj.destroy();
    },
  },
};
</script>

<style scoped>
input {
  border: 1px solid black;
}
div.ajax-items {
  cursor: pointer;
  background: white;
  border: 1px solid black;
  margin-top: -1px;
  position: absolute;
}
div.ajax-items ul li {
  cursor: pointer;
}
div.ajax-items ul li:hover {
  /* font-weight: bold; */
  background: #f0f0f0;
}
div.selected {
  cursor: pointer;
}
div.selected:hover {
  text-decoration: line-through;
}
</style>
