import Dashboard from "../views/Dashboard.vue";
import About from "../views/About.vue";
import Table from "../views/Table.vue";
import DetailsTender from "../views/DetailsTender.vue";
import DocumentsTender from "../views/DocumentsTender.vue";
import Login from "../views/Login.vue";
import UserAccount from "../views/UserAccount.vue";
import Register from "../views/Register.vue";

export default [
  { path: "/", component: Dashboard },
  { path: "/about", component: About },
  {
    path: "/tenders",
    component: Table,
    props: (route) => ({ query: route.query.params }),
  },
  { path: "/detailsTender/:id", component: DetailsTender },
  { path: "/detailsTender/:id/docs", component: DocumentsTender },
  { path: "/cpvsubscribe", component: Table },
  { path: "/login", component: Login },
  { path: "/user", component: UserAccount },
  { path: "/register", component: Register },
];
