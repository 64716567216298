var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"\n      cursor-pointer\n      block\n      bg-blue-800\n      text-white\n      font-semibold\n      py-2\n      px-4\n      mb-4\n      w-max\n    ",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Back to Details ")]),_c('vue-good-table',{ref:"table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"fixed-header":true,"max-height":"75vh","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
      enabled: true,
    },"sort-options":{
      enabled: true,
    },"select-options":{
      enabled: _vm.toggleSelect,
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 50,
      perPageDropdown: [5, 10, 25, 50],
      dropdownAllowAll: true,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All',
    }},on:{"on-selected-rows-change":_vm.rowSelected,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-search":_vm.onSearch,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.onRowClickDocs,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('span',{staticClass:"selections-info align-bottom"},[_vm._v(" "+_vm._s(_vm.selections.length)+" rows selected. ")]),_c('span',{staticClass:"btn btn-sm btn-warning mx-2",on:{"click":_vm.clearSelections}},[_vm._v("Clear")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }