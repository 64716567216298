<template>
  <div>
    <h1 class="text-3xl font-bold mb-2">Register</h1>
    <InputField
      @change="onchange"
      :fieldName="'Username'"
      :inputName="'username'"
      :inputPlaceholder="'Username'"
    />
    <InputField
      @change="onchange"
      :fieldName="'First Name'"
      :inputName="'firstName'"
      :inputPlaceholder="'First Name'"
    />
    <InputField
      @change="onchange"
      :fieldName="'Last Name'"
      :inputName="'lastName'"
      :inputPlaceholder="'Last Name'"
    />
    <InputField
      @change="onchange"
      :fieldName="'E-Mail'"
      :inputName="'email'"
      :inputPlaceholder="'E-Mail'"
      :inputType="'email'"
    />
    <InputField
      @change="onchange"
      :fieldName="'Password'"
      :inputName="'password'"
      :inputPlaceholder="'Password'"
      :inputType="'password'"
    />
    <div
      class="
        flex-initial
        bg-blue-800
        text-white
        font-semibold
        flex
        items-center
        justify-center
        py-2
        px-4
        mr-4
        w-32
        cursor-pointer
      "
      @click="register"
    >
      Register
    </div>
    <div class="mt-5 text-green-600" v-if="success">
      Successfully created User!
    </div>
    <div class="mt-5 text-red-600" v-else-if="errorMessage">
      Error: {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import InputField from "../components/InputField.vue";
import TAjax from "../mixins/TAjax.vue";

export default {
  name: "Register",
  components: { InputField },
  mixins: [TAjax],
  data() {
    return {
      inputData: {},
      errorMessage: "",
      success: false,
    };
  },
  methods: {
    onchange(data) {
      this.inputData[data.field] = data.value;
      // console.log(this.inputData);
    },
    async register() {
      let params = {
        firstName: this.inputData.firstName,
        lastName: this.inputData.lastName,
        username: this.inputData.username,
        email: this.inputData.email,
        password: this.inputData.password,
      };

      const result = await this.postData(
        "http://localhost:1111/register",
        params
      );
      const data = result.data;

      if (data.errorMessage) {
        this.errorMessage = data.errorMessage;
      } else {
        this.success = true;
      }
    },
  },
};
</script>

<style></style>
