var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('vue-good-table',{ref:"table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"fixed-header":true,"max-height":"60vh","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
        enabled: _vm.tableFilter,
      },"sort-options":{
        enabled: true,
      },"select-options":{
        enabled: _vm.toggleSelect,
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 50,
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: true,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }},on:{"on-selected-rows-change":_vm.rowSelected,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-search":_vm.onSearch,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.onRowClick,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('span',{staticClass:"\n            selections-info\n            text-xs\n            font-semibold\n            tracking-wide\n            text-left text-gray-500\n            uppercase\n            border-b\n            dark:border-gray-700\n            bg-gray-50\n            dark:text-gray-400 dark:bg-gray-800\n          "},[_vm._v(" "+_vm._s(_vm.selections.length)+" rows selected. ")]),_c('span',{staticClass:"btn btn-sm btn-warning mx-2",on:{"click":_vm.clearSelections}},[_vm._v("Clear")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }