<template>
  <div class="selected-container">
    <h3>Selected</h3>
    <ul>
      <li
        class="cursor-pointer"
        :class="item.recursive ? 'bold' : ''"
        v-for="(item, index) in added"
        :key="index"
        @click="removeItem(item.code)"
        bold
      >
        {{ item.code }} - {{ item.label }}
      </li>
    </ul>
    <!-- <Checkbox
      class="mt-2"
      :name="'subscribeCpv'"
      :text="'Subscribe to the selected CPV\'s'"
      @change="subscribedCpv"
      v-show="!emailSent"
    /> -->
    <div
      class="flex flex-col gap-y-1"
      v-if="!emailSent && logged_in && showSubscribeButton"
    >
      <div
        class="
          flex-initial
          bg-blue-800
          text-white
          font-semibold
          flex
          items-center
          justify-center
          py-2
          px-4
          mr-4
          mt-4
          w-20
          cursor-pointer
        "
        @click="submitEmail"
      >
        Subscribe
      </div>
    </div>
    <span v-if="emailSent">Subscribed successfully</span>
  </div>
</template>

<script>
import "../assets/tailwind.css";
import TAjax from "../mixins/TAjax.vue";
import Cookies from "../mixins/Cookies.js";
import User from "../mixins/User.vue";

export default {
  name: "CpvSelected",
  mixins: [TAjax, Cookies, User],
  props: ["added", "showSubscribed", "showSubscribeButton"],
  components: {},
  data() {
    return {
      checkedSubscribe: false,
      logged_in: false,
      emailSent: false,
      userMail: "",
    };
  },
  methods: {
    removeItem(code) {
      this.$emit("delete", code);
    },
    async submitEmail() {
      // console.log("Sending data to server...");

      let data = await this.loadUser();

      this.userMail = data.email;

      const postObj = {
        type: "cpv",
        filter: this.added,
        email: this.userMail,
      };

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/submitEmail/`,
        postObj
      );

      if (result.status == 200) {
        // this.emailSent = true;
        this.$emit("loadItems", true);
      }
    },
  },
  async created() {
    let data = await this.loadUser();
    if (!data) {
      this.logged_in = false;
    } else {
      this.logged_in = true;
    }
  },
  computed: {
    display() {
      return this.userMail.length > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
li:hover {
  text-decoration: line-through;
}
h3 {
  font-size: 1.25rem;
  text-decoration: underline;
}
.bold {
  font-weight: bold;
}
</style>
