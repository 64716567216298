<template>
  <div>
    <h1>About</h1>
    <p>
      This is the Tenderscraper - a Project that displays the maltese e-Tenders
      from the original Government-Website in a more organized and modern
      fashion.
      <br /><br />
      It was developed with User-Friendliness in mind, without compromising the
      User-Experience. It brings, in contrast to the Government-Website, many
      new Features, for example an E-Mail notifier with subscribable options, a
      Dashboard for CPVs and many more.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
body h1 {
  font-size: 2rem;
  font-weight: 600;
}
body h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
a.link {
  color: blue;
}
</style>
