<template>
  <div class="flex flex-col">
    <div class="title mb-1">
      <label :for="sName">{{ fieldName }}</label>
    </div>
    <div class="field">
      <select
        class="
          bg-white
          w-2/4
          rounded-none
          border border-solid
          py-2
          px-4
          border-blue-500
        "
        v-bind:name="sName"
        :id="sId"
        @change="onChange"
        v-model="value"
      >
        <option value="">{{ firstValue }}</option>
        <option
          :value="option"
          v-for="(option, index) in options"
          :key="index"
          :selected="option == selectedOption"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: [
    "sName",
    "sId",
    "fieldName",
    "options",
    "firstValue",
    "selectedOption",
  ],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onChange() {
      let obj = {};
      obj.field = this.sName;
      obj.value = this.value;
      this.$emit("change", obj);
    },
  },
  created() {
    this.value = this.selectedOption;

    // console.log("this.value");
    // console.log(this.value);
  },
};
</script>

<style></style>
