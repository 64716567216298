<template>
  <div class="mb-2 border border-blue-800 w-50">
    <h3 class="p-2" @click="showFields">Advanced Search</h3>

    <div class="p-2 mb-4" v-show="showInner">
      <InputField
        @change="emitChanges"
        :fieldName="'Title'"
        :inputName="'cf_T_Title'"
        :defaultValue="filterData.cf_T_Title"
      />
      <InputField
        @change="emitChanges"
        :fieldName="'CfT CA Unique ID'"
        :inputName="'cf_T_Ca_Unique_Id'"
        :defaultValue="filterData.cf_T_Ca_Unique_Id"
      />
      <InputField
        @change="emitChanges"
        :fieldName="'Name of Contracting Authority'"
        :inputName="'ca'"
        :defaultValue="filterData.ca"
      />
      <Select
        @change="emitChanges"
        :fieldName="'Workspace Status'"
        :sName="'status'"
        :options="workspaceStatusOptions"
        :firstValue="'-'"
        v-if="waitForRender"
        :selectedOption="filterData.status"
      />
      <Select
        @change="emitChanges"
        :fieldName="'Procurement Type'"
        :sName="'procurement_Type'"
        :options="procurementTypeOptions"
        :firstValue="'-'"
        v-if="waitForRender"
        :selectedOption="filterData.procurement_Type"
      />
      <Select
        @change="emitChanges"
        :fieldName="'Procedures'"
        :sName="'procedure'"
        :options="proceduresOptions"
        :firstValue="'-'"
        v-if="waitForRender"
        :selectedOption="filterData.procedure"
      />
      <div class="flex flex-row gap-x-4">
        <a
          v-if="!showSubscribed"
          @click="triggerSearch"
          class="
            cursor-pointer
            block
            bg-blue-800
            text-white
            font-semibold
            py-2
            px-4
            mt-5
            w-max
          "
          >Search
        </a>
        <a
          v-show="logged_in"
          @click="submitEmail"
          class="
            cursor-pointer
            block
            bg-blue-800
            text-white
            font-semibold
            py-2
            px-4
            mt-5
            w-max
          "
          >Subscribe
        </a>
        <a
          @click="resetSearch"
          class="
            cursor-pointer
            block
            bg-blue-800
            text-white
            font-semibold
            py-2
            px-4
            mt-5
            w-max
          "
          v-show="resetBtn"
          >Reset
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "./InputField.vue";
import Select from "./Select.vue";
import User from "../mixins/User.vue";
import "../assets/tailwind.css";

export default {
  name: "AdvancedSearch",
  mixins: [User],
  props: ["showSubscribed"],
  components: { InputField, Select },
  data() {
    return {
      showInner: false,
      resetBtn: false,
      logged_in: false,
      filterData: {
        // cf_T_Title: "",
        // cf_T_Ca_Unique_Id: "",
        // ca: "",
        // status: "",
        // procurement_Type: "",
        // procedure: "",
      },
      workspaceStatusOptions: [
        "Cancelled",
        "For Archival",
        "Proposal Submission Closed",
        "Terminated",
        "Established",
        "Under Evaluation",
        "Concluded",
        "Tender Submission",
        "Proposal Submission",
        "Awaiting Tender Opening",
        "Archived",
        "Under Appeal",
        "Cancelled",
        "Awarded",
      ],
      procurementTypeOptions: ["Supplies", "Services", "Works"],
      proceduresOptions: [
        "Specific Contract",
        "Open",
        "Restricted",
        "Accelerated restricted",
        "Competitive dialogue",
        "Simplified",
        "Without prior Contract Notice",
        "Dynamic Purchasing System",
        "DPS Specific Contract",
        "Accelerated open",
        "Competitive with negotiation",
        "Accelerated competitive with negotiation",
        "Negotiated without prior call for competition",
        "Negotiated with prior call for competition",
        "Involving negotiations",
        "Negotiated with publication",
        "Accelerated negotiated with publication",
        "Negotiated without prior publication (one stage)",
        "Innovation partnership",
        "Preliminary Market Consultation",
      ],
      title: "",
      cfTCaUniqueId: "",
      contractingAuthority: "",
      workspaceStatus: "",
      procurementType: "",
      procedures: "",
    };
  },
  methods: {
    showFields() {
      this.showInner = !this.showInner;
    },
    emitChanges(val) {
      if (val.value) {
        this.filterData[val.field] = val.value;
      } else {
        delete this.filterData[val.field];
      }
    },
    triggerSearch() {
      // console.log("triggerSearch()");
      if (Object.keys(this.filterData).length >= 1) this.resetBtn = true;
      else this.resetBtn = false;
      this.$emit("submit", this.filterData);
    },
    resetSearch() {
      this.resetBtn = false;
      this.$emit("submit", {});
    },
    async submitEmail() {
      // console.log("Sending data to server...");

      let data = await this.loadUser();

      this.userMail = data.email;

      const postObj = {
        type: "search",
        filter: this.filterData,
        email: this.userMail,
      };

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/submitEmail/`,
        postObj
      );

      if (result.status == 200) {
        // this.emailSent = true;
        this.$emit("loadItems", true);
      }
    },
    async getSubscribedConfig() {
      let userdata = await this.loadUser();

      const postObj = {
        email: userdata.email,
        type: "search",
      };

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/subscribed/`,
        postObj
      );

      const data = result.data;

      this.filterData = data[0];

      console.log(this.filterData);
    },
  },
  computed: {
    waitForRender() {
      if (this.showSubscribed) {
        if (Object.keys(this.filterData).length > 0) {
          return true;
        }
      } else {
        return true;
      }

      return false;
    },
  },
  async created() {
    let data = await this.loadUser();
    if (!data) {
      this.logged_in = false;
    } else {
      this.logged_in = true;
      if (this.showSubscribed) {
        this.getSubscribedConfig();
      }
    }
  },
};
</script>

