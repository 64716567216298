<template>
  <div class="cpv-filter w-50">
    <h2 class="px-2 py-4 text-3xl">Subscriptions</h2>
    <CpvSubscribed
      class="mt-4"
      v-if="showSubscribed"
      :load="load"
      @loading="loadSubscribed"
    />

    <hr class="my-4" />

    <h2 class="px-2 py-4 text-3xl">Add Subscriptions</h2>

    <div class="inner">
      <CpvTree
        @click="add"
        @dblclick="add_recursive"
        :deletedItem="deletedItem"
      />
      <CpvSelected
        class="cpv-selected"
        :added="added"
        @delete="deleteItem"
        v-show="CPVSelected"
        :showSubscribed="showSubscribed"
        :showSubscribeButton="showSubscribeButton"
        @loadItems="loadSubscribed"
      />
      <div class="flex flex-row gap-x-4" v-if="!showSubscribed">
        <a
          @click="triggerSearch"
          class="
            cursor-pointer
            block
            bg-blue-800
            text-white
            font-semibold
            py-2
            px-4
            mt-5
            w-max
          "
          v-show="CPVSelected"
          >Search
        </a>
        <a
          @click="resetSearch"
          class="
            cursor-pointer
            block
            bg-blue-800
            text-white
            font-semibold
            py-2
            px-4
            mt-5
            w-max
          "
          v-show="resetBtn"
          >Reset
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CpvTree from "./CpvTree.vue";
import CpvSelected from "./CpvSelected.vue";
import CpvSubscribed from "./CpvSubscribed.vue";

export default {
  name: "CpvMainPanel",
  components: { CpvTree, CpvSelected, CpvSubscribed },
  props: ["showSubscribed", "showSubscribeButton"],
  data() {
    return {
      added: [],
      resetBtn: false,
      showInner: false,
      load: false,
      deletedItem: 0,
    };
  },
  methods: {
    showFields() {
      this.showInner = !this.showInner;
    },
    add(data) {
      if (!this.containsObject(data, this.added)) {
        this.added.push(data);
      }
    },
    add_recursive(data) {
      this.added.push(data);
      let parent = data.parent;
      // console.log(this.added);

      this.added.map((c) => {
        // console.log(`${c.code} == ${parent}`);
        if ("children" in c == false) {
          if (c.code == parent) c.recursive = true;
          else if (c.recursive == false) c.recursive = false;
        }
      });

      // console.log("this.added");
      // console.log(this.added);
    },
    containsObject(obj, list) {
      var i;
      obj = JSON.stringify(obj);
      for (i = 0; i < list.length; i++) {
        let listitem = JSON.stringify(list[i]);
        if (listitem == obj) {
          return true;
        }
      }

      return false;
    },
    deleteItem(code) {
      this.deletedItem = code;
      this.added = this.added.filter((c) => {
        if (
          ("code" in c && c.code != code) ||
          ("parent" in c && c.parent != code)
        ) {
          return true;
        }
      });
      // console.log(code);
    },
    triggerSearch() {
      this.$emit("submit", this.added);
      this.resetBtn = true;
    },
    resetSearch() {
      this.resetBtn = false;
      this.$emit("submit", {});
    },
    loadSubscribed(bool) {
      this.load = bool;
    },
  },
  computed: {
    CPVSelected() {
      return this.added.length > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
div.cpv-filter {
  border: 1px solid black;
  /* padding: 1rem; */
  margin-bottom: 1rem;
}
div.cpv-filter h3.head {
  padding: 0.5rem;
  cursor: pointer;
}

div.cpv-filter h3.head:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

div.cpv-filter .inner {
  margin: 0 0 1rem 0;
  padding: 0.5rem 1rem;
}
.cpv-selected {
  margin-top: 1rem;
}
</style>
