<template>
  <div>
    <a
      @click="$router.go(-1)"
      class="
        cursor-pointer
        block
        bg-blue-800
        text-white
        font-semibold
        py-2
        px-4
        mb-4
        w-max
      "
      >Back to Overview
    </a>
    <a
      @click="gotoDocs"
      class="
        cursor-pointer
        block
        bg-blue-800
        text-white
        font-semibold
        py-2
        px-4
        mb-4
        w-max
      "
      >Documents
    </a>
    <div v-for="props in tender" :key="props.id">
      <h1 class="text-lg font-bold">{{ props.cfTTitle }}</h1>

      <div class="details mt-5 flex flex-col gap-y-2">
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold"
            >Name of Contracting Authority:
          </span>
          <span>{{ props.nameOfContractingAuthority }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Published on behalf of: </span>
          <span>{{ props.publishedOnBehalfOf }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">CfT CA Unique ID:</span>
          <span>{{ props.cfTCaUniqueId }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Evaluation Mechanism:</span>
          <span>{{ props.evaluationMechanism }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Description:</span>
          <span>{{ props.description }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Procurement Type:</span>
          <span>{{ props.procurementType }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">CPC Category:</span>
          <span>{{ props.cpcCategory }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Directive:</span>
          <span>{{ props.directive }}</span>
        </span>
        <span class="flex flex-row gap-x-2 gap-x-1 items-center">
          <span class="w-60 block font-semibold">Procedure:</span>
          <span>{{ props.procedure }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">CfT Involves:</span>
          <span>{{ props.cfTInvolves }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">CPV Codes:</span>
          <span>{{ cpvsCodes }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">Inclusion of e-Auctions:</span>
          <span>{{ props.inclusionOfEAuctions == 1 ? "Yes" : "No" }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">NUTS codes:</span>
          <span>{{ props.nutsCodes }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Above or Below threshold:
          </span>
          <span>{{ props.aboveOrBelowThreshold }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">Payment Options:</span>
          <span>{{ props.paymentOptions }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Time-limit for receipt of tenders or requests to participate:
          </span>
          <span>
            {{
              formatDate(
                props.timeLimitForReceiptOfTendersOrRequestsToParticipate
              )
            }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Deadline for dispatching invitations:
          </span>
          <span>{{ props.deadlineForDispatchingInvitations }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            End of clarification period:
          </span>
          <span>{{ formatDate(props.endOfClarificationPeriod) }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Upload of documents within the clarifications:
          </span>
          <span>
            {{
              props.uploadOfDocumentsWithinTheClarifications == 1 ? "Yes" : "No"
            }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">Tenders Opening Date: </span>
          <span>{{ formatDate(props.tendersOpeningDate) }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Allow suppliers to Register for Notifications:
          </span>
          <span>
            {{
              props.allowSuppliersToRegisterForNotifications == 1 ? "Yes" : "No"
            }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Contract awarded in Lots:
          </span>
          <span>
            {{ props.contractAwardedInLots == 1 ? "Yes" : "No" }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">EU funding: </span>
          <span>{{ props.euFunding == 1 ? "Yes" : "No" }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold"
            >Multiple tenders will be accepted:
          </span>
          <span>
            {{ props.multipleTendersWillBeAccepted == 1 ? "Yes" : "No" }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold"
            >Date of Publication/Invitation:
          </span>
          <span>{{ formatDate(props.dateOfPublicationInvitation) }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold"
            >TED links for published notices:
          </span>
          <span>{{ props.tedLinksForPublishedNotices }} </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">Date of Awarding: </span>
          <span>{{ props.dateOfAwarding }}</span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">
            Does this Call fall under the scope of GPP?:
          </span>
          <span>
            {{ props.doesThisCallFallUndertheScopeOfGpp == 1 ? "Yes" : "No" }}
          </span>
        </span>
        <span class="flex flex-row gap-x-2 items-center">
          <span class="w-60 block font-semibold">Certification check: </span>
          <span>{{ props.certificationCheck == 1 ? "Yes" : "No" }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import TAjax from "../mixins/TAjax.vue";
import "../assets/tailwind.css";
import router from "../router/router.js";

export default {
  name: "detailsTender",
  mixins: [TAjax],
  data() {
    return {
      tender: [],
      cpvsCodes: "",
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "";

      let dateArr = dateString.split("T"); // array(0 => yyyy-mm-dd, 1 => hh:mm:ss:SSSSZ)

      dateArr = dateArr[0];

      dateArr = dateArr.split("-");

      // console.log(dateArr);

      return dateArr[0] + "/" + dateArr[1] + "/" + dateArr[2];
    },
    async loadTenderDetails() {
      // console.log("loadTenderDetails()");
      const tenderId = this.$route.params.id;

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/tenders/:id`,
        {
          id: tenderId,
        }
      );

      const data = result.data;

      // console.log(data.tender);

      this.tender = [data.tender];
      this.cpvsCodes = data.cpvs.map((cpvs) => cpvs.code).join();

      // console.log(this.tender);
    },
    gotoDocs() {
      router.push({
        path: "/detailsTender/" + this.$route.params.id + "/docs",
      });
    },
  },
  created() {
    this.loadTenderDetails();
  },
};
</script>

<style></style>
