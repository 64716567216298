<script>
import debounce from "../mixins/Debounce";
import router from "../router/router.js";

export default {
  data() {
    return {
      sortingTypes: {
        asc: "Asc",
        desc: "Desc",
      },
      table_headers: [],
    };
  },
  mixins: [debounce],
  watch: {
    rows() {
      var self = this;
      self.$nextTick(() => {
        var tables = document.querySelectorAll("table");
        self.collectTableHeaders(tables, () => {
          self.setDataLabels(tables);
        });
      });
    },
  },
  methods: {
    rowSelected(selectedOnThisPage) {
      //Managing selections manually so that we can retain selections across multiple pages
      const self = this;
      const currentPagePks = self.rows.map((c) => c.PK_Id);
      const thisPageSelection = selectedOnThisPage.selectedRows.map(
        (c) => c.PK_Id
      );

      let globalSelection = this.selections;
      let newValues = this.difference(thisPageSelection, globalSelection);
      globalSelection = this.selections.concat(newValues);
      const globalSelectionNotOnThisPage = globalSelection.filter(
        (c) => !currentPagePks.includes(c)
      );
      const selectionsOnThisPageWithRemovals = globalSelection.filter(
        (c) => currentPagePks.includes(c) && thisPageSelection.includes(c)
      );
      this.selections = globalSelectionNotOnThisPage.concat(
        selectionsOnThisPageWithRemovals
      );
    },
    difference(arr1, arr2) {
      let a2Set = new Set(arr2);
      return arr1.filter(function (x) {
        return !a2Set.has(x);
      });
    },
    intersection(arr1, arr2) {
      let a2Set = new Set(arr2);
      return arr1.filter(function (x) {
        return a2Set.has(x);
      });
    },
    clearSelections() {
      this.selections = [];
      const rows = this.$refs["table"].filteredRows[0].children;
      rows.forEach((item) => {
        this.$set(item, "vgtSelected", false);
      });
    },
    collectTableHeaders(tables, callback) {
      if (tables.length) {
        for (var t = 0; t < tables.length; t++) {
          var table = tables[t];
          var thArray = new Array();
          var tableHeaders = table.querySelectorAll("thead th");

          for (var th = 0; th < tableHeaders.length; th++) {
            var tableHead = tableHeaders[th];
            thArray.push(
              !tableHead.innerText.length ? "Empty" : tableHead.innerText
            );
          }

          this.table_headers.push(thArray);
        }
      }
      callback();
    },
    setDataLabels(tables) {
      if (tables.length) {
        for (var t = 0; t < tables.length; t++) {
          var table = tables[t];
          var tableRows = table.querySelectorAll("tbody tr");
          for (var tr = 0; tr < tableRows.length; tr++) {
            var tableRow = tableRows[tr];
            var tableDataCells = tableRow.querySelectorAll("td");
            for (var td = 0; td < tableDataCells.length; td++) {
              var tableDataCell = tableDataCells[td];
              tableDataCell.dataset.label = this.table_headers[t][td];
            }
          }
        }
      }
    },
    updateTimParams(newProps) {
      this.vgtServerParams = { ...this.vgtServerParams, ...newProps };
    },
    onPageChange(params) {
      this.updateTimParams({
        pages: {
          size: params.currentPerPage,
          page: params.currentPage,
        },
      });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateTimParams({
        pages: {
          size: params.currentPerPage,
          page: params.currentPage,
        },
      });
      this.loadItems();
    },
    onSortChange(params) {
      if (params.length > 0) {
        const sortBy = params[0].field;
        const sortOrder = params[0].type;
        this.updateTimParams({
          sort: { sortBy, sortOrder },
        });
      }

      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateTimParams(params);
      this.loadItems();
    },
    onSearch(params) {
      const self = this;
      this.updateTimParams({
        searchString: params.searchTerm,
        pages: {
          size: this.vgtServerParams.pages.size,
          page: 1,
        },
      });
      this.pgtDebounce(function () {
        self.loadItems();
      }, 800);
    },
    onRowClick(params) {
      const id = params.row.id;
      router.push({ path: "/detailsTender/" + id });
    },
    onRowClickDocs(params) {
      // console.log(params);
      const URL = `https://www.etenders.gov.mt/epps/cft/downloadContractDocument.do?documentId=${params.row.docId}&resourceId=null`;
      window.open(URL, "_blank");
    },
  },
};
</script>
