<template>
  <div>
    <div>
      <vue-good-table
        ref="table"
        @on-selected-rows-change="rowSelected"
        :columns="columns"
        :rows="rows"
        :fixed-header="true"
        max-height="60vh"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-search="onSearch"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-row-click="onRowClick"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :search-options="{
          enabled: tableFilter,
        }"
        :sort-options="{
          enabled: true,
        }"
        :select-options="{
          enabled: toggleSelect,
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 50,
          perPageDropdown: [5, 10, 25, 50],
          dropdownAllowAll: true,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }"
      >
        <div slot="selected-row-actions">
          <span
            class="
              selections-info
              text-xs
              font-semibold
              tracking-wide
              text-left text-gray-500
              uppercase
              border-b
              dark:border-gray-700
              bg-gray-50
              dark:text-gray-400 dark:bg-gray-800
            "
          >
            {{ selections.length }} rows selected.
          </span>
          <span class="btn btn-sm btn-warning mx-2" @click="clearSelections"
            >Clear</span
          >
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<style>
table.vgt-table {
  background: var(--white);
  border: none; /* border: 1px var(--black) solid; */
}
td,
th {
  line-height: 1.15;
}
table.vgt-table.bordered tr {
  border-bottom: 1px var(--gray) solid !important;
}
tr {
  /* border-bottom: 1px var(--gray) solid; */
}
.vgt-table th.sortable button::after {
}
.vgt-wrap .vgt-global-search__input .input__icon .magnifying-glass {
  margin-top: 10px;
  margin-left: 13px;
  display: block;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
}

.vgt-inner-wrap {
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: none; /* border: 1px var(--black) solid; */
  border-top: 1px solid;
  @apply border-blue-800;
  box-shadow: none;
}

.vgt-wrap form[role="search"] input {
  margin: 0.25rem;
  display: block;
  width: inherit;
  height: inherit; /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  @apply text-gray-800;
  background-color: #fff;
  /* background-clip: padding-box; */
  /* border: 1px solid #ced4da; */
  border-radius: 0rem;
  box-shadow: none;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}
.vgt-table.bordered th {
  border: none; /* border: 1px solid var(--black); */
}
.vgt-table.bordered tr:nth-child(2n) {
  /* background: lightgray; */
  border: none;
}

.vgt-table.bordered td {
  border: none;
  /* border-right: 1px solid var(--black); */
}
.vgt-table thead th {
  background: transparent;
}
table.vgt-table td {
  @apply px-4 py-3;
}
.vgt-table thead tr {
  @apply text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b border-gray-700 bg-gray-50;
}
.vgt-table tbody {
  @apply bg-white divide-y;
}
* {
  scrollbar-width: initial;
  scrollbar-color: var(--black) var(--white);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--white);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--black);
  border-radius: 20px;
  border: 3px solid var(--white);
}

.vgt-inner-wrap .vgt-wrap__footer {
  border: none;
  border-top: 1px solid var(--black);
  background: none;
}
thead
  th.vgt-left-align.text-xs
  font-semibold
  tracking-wide
  text-left
  text-gray-500
  uppercase
  border-b
  dark:border-gray-700
  bg-gray-50
  dark:text-gray-400
  dark:bg-gray-800 {
  vertical-align: bottom;
}

table.vgt-table td,
.vgt-table thead th {
  color: var(--black);
}
.vgt-wrap__footer {
  color: var(--black);
}
.vgt-wrap__footer .footer__row-count__select {
  color: var(--black);
}
.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-btn,
.vgt-wrap__footer .footer__navigation__page-info {
  color: var(--black);
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left-color: var(--black);
}
.vgt-wrap__footer .footer__row-count::after {
  border-top-color: var(--black);
}
.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right-color: var(--black);
}
.vgt-input,
.vgt-select {
  /* width: 100%; */
  /* height: 32px; */
  /* line-height: 1; */
  /* display: block; */
  /* font-size: 14px; */
  /* font-weight: 400; */
  /* padding: 6px 12px; */
  color: var(--black);
  /* border-radius: 4px; */
  /* box-sizing: border-box; */
  /* background-image: none; */
  /* background-color: #fff; */
}
div.vgt-wrap form[role="search"] input {
  border: 1px solid;
  @apply border-blue-800;
}
</style>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import VueGoodTableRemote from "../mixins/VueGoodTableRemote.vue";
import TAjax from "../mixins/TAjax.vue";

export default {
  name: "Table",
  mixins: [VueGoodTableRemote, TAjax],
  components: {
    VueGoodTable,
  },
  props: ["filterData", "cpvCodes", "cpvIds", "login", "isCPV"],
  data() {
    return {
      toggleSelect: false,
      advancedSearchUsed: false,
      tableFilter: true,
      selections: [],
      searchTerm: "",
      isLoading: false,
      totalRecords: 0,
      vgtServerParams: {
        pages: {
          page: 1,
          size: 50,
        },
        sort: {
          sortBy: "Id",
          sortOrder: "asc",
        },
      },
      columns: [
        {
          label: "Cft Id",
          field: "cf_T_Ca_Unique_Id",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
        },
        {
          label: "Title",
          field: "cf_T_Title",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
        },
        {
          label: "Procedure",
          field: "procedure",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
        },
        {
          label: "Status",
          field: "status",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
        },
        {
          label: "Submission Deadline",
          field: "tenders_Submission_Deadline",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd MMM yyyy",
          tdClass: "text-right",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
          width: "8rem",
        },
        {
          label: "Opening Date",
          field: "tenders_Opening_Date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd MMM yyyy",
          tdClass: "text-right",
          thClass:
            "text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
          width: "8rem",
        },
      ],
      rows: [],
      parsedFilters: {},
      localCpvIds: [],
    };
  },
  created() {
    if (this.isCPV) {
      console.log(this.login);
    }
  },
  watch: {
    filterData: {
      // since the watcher is deep: newValue = oldValue
      handler(obj) {
        // console.log("this.watch.filterData.handler()");
        this.parsedFilters = obj;
        if (Object.keys(obj).length <= 0) {
          this.tableFilter = true;
        } else {
          this.tableFilter = false;
          if (!this.cpv) {
            this.advancedSearchUsed = true;
          }

          this.vgtServerParams.pages.page = 1;
          this.vgtServerParams.pages.size = 50;
          this.$refs["table"].reset();
        }
        this.loadItems();
      },
      deep: true,
    },
    cpvIds: {
      // since the watcher is deep: newValue = oldValue
      handler(obj) {
        // console.log("this.watch.filterData.handler()");
        this.localCpvIds = obj;
        this.tableFilter = false;

        this.vgtServerParams.pages.page = 1;
        this.vgtServerParams.pages.size = 50;
        this.$refs["table"].reset();
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    formatDate(dateString) {
      return Date(dateString);
    },
    async loadItems() {
      this.isLoading = true;
      this.rows = [];

      let postObj = {
        limit: this.vgtServerParams.pages.size,
        offset:
          (this.vgtServerParams.pages.page - 1) *
          this.vgtServerParams.pages.size,
        orderBy: this.vgtServerParams.sort.sortBy,
        order: this.vgtServerParams.sort.sortOrder,
      };

      // if advanced search is used, use expanded filter
      if (
        this.advancedSearchUsed &&
        Object.keys(this.parsedFilters).length > 0
      ) {
        postObj.advancedSearch = true;
        postObj.filterData = this.parsedFilters;
      } else if (this.cpv && Object.keys(this.parsedFilters).length > 0) {
        postObj.cpvFilter = true;
        postObj.filterData = this.parsedFilters;
      } else {
        postObj.searchString = this.vgtServerParams.searchString;
      }
      if (this.localCpvIds.length) {
        postObj.cpvIds = this.localCpvIds;
      }

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/tenders`,
        postObj
      );

      // console.log(result.data);
      const parsed = result.data;
      this.totalRecords = parsed.count;
      this.rows = parsed.rows;

      this.$nextTick(() => {
        const rows = this.$refs["table"].filteredRows[0].children;
        rows.forEach((item) => {
          if (this.selections.includes(item.PK_Id))
            this.$set(item, "vgtSelected", true);
        });
      });
    },
  },
  computed: {
    cpv() {
      if (this.$route.path == "/cpvsubscribe") return true;
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
  // mounted() {},
};
</script>
