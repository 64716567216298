<template>
  <div class="flex flex-col my-2">
    <div class="title mb-1" v-if="fieldName">
      <span>{{ fieldName }}</span>
    </div>
    <div class="field">
      <input
        class="bg-white w-2/4 rounded-none border py-2 px-4 border-blue-500"
        v-bind:type="inputType"
        :name="inputName"
        :id="inputId"
        :value="defaultValue"
        :placeholder="inputPlaceholder"
        @change="onChange"
        @input="text = $event.target.value"
      />
    </div>
  </div>
</template>

<script>
import "../assets/tailwind.css";

export default {
  name: "InputField",
  props: [
    "fieldName",
    "inputType",
    "inputName",
    "inputId",
    "defaultValue",
    "inputPlaceholder",
  ],
  data() {
    return {
      text: "",
    };
  },
  methods: {
    onChange() {
      let obj = {};
      obj.field = this.inputName;
      obj.value = this.text;
      this.$emit("change", obj);
    },
  },
  // beforeUpdate() {
  // if (this.text != this.defaultValue) {
  //   this.defaultValue = this.text;
  // }
  // },
  beforeUpdate() {
    // console.log("UPDATE");
    // this.text = this.defaultValue;
  },
  computed: {
    value() {
      // console.log("value");
      return "";
    },
  },
};
</script>
