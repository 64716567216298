<template>
  <!-- <div> -->
  <li class="cursor-pointer" :style="indentation">
    <div class="flex flex-row justify-between items-center py-2 px-2">
      <div>
        <a @click="openClose">[{{ openCloseIcon }}]</a>
        <a @click="add({ label, code, cpvId })" bold class="ml-1">
          {{ code }} - {{ label }}</a
        >
      </div>
      <div>
        <a
          class="
            float-right
            ml-1
            flex flex-initial
            bg-white
            text-gray-800 text-xs
            border-solid border-2 border-blue-800
            font-semibold
            items-center
            justify-center
            mr-4
            w-20
            cursor-pointer
          "
          @click="add_recursive({ label, code, cpvId })"
        >
          Add all
        </a>
      </div>
    </div>

    <ul>
      <TreeItem
        :depth="depth + 1"
        v-for="items in fetchedChildren"
        :key="items.code + items.id"
        :label="items.description"
        :code="items.code"
        :cpvId="items.id"
        :deletedItem="deletedItem"
        v-show="open"
        @click="add"
        @dblclick="add_recursive"
      />
    </ul>
  </li>
  <!-- </div> -->
</template>

<script>
import "../assets/tailwind.css";
import TAjax from "../mixins/TAjax.vue";

export default {
  name: "TreeItem",
  mixins: [TAjax],
  props: ["depth", "label", "code", "cpvId", "deletedItem"],
  watch: {
    deletedItem: {
      handler() {
        // this will be run immediately on component creation.
        if (this.code == this.code) {
          this.selected = false;
        }
      },
    },
  },
  data() {
    return {
      fetchedChildren: [],
      loadedChilds: false,
      openCloseIcon: "+",
      open: false,
      selected: false,
      childs: {
        parent: 0,
        children: [],
      },
    };
  },
  methods: {
    async renderChilds(code) {
      if (!this.loadedChilds) {
        // console.log(`rendering childs from parent ${code}...`);
        const result = await this.postData(
          `https://tendersbackend.prettyneat.io/api/cpvfilter/cpvs/:parent`,
          {
            parent: code,
          }
        );
        const data = result.data;

        data.map((c) => {
          this.fetchedChildren.push(c);
        });

        this.loadedChilds = true;

        // console.log("children rendered:");
        // console.log("this.fetchedChildren:");
        // console.log(this.fetchedChildren);

        return true;
      } else {
        // console.error("Children already loaded");
        return false;
      }
    },
    async openClose() {
      if (!this.open) {
        await this.renderChilds(this.code);
        this.openCloseIcon = "-";
        this.open = true;
      } else {
        this.openCloseIcon = "+";
        this.open = false;
      }
    },
    add(data) {
      // console.log(`Emitting ${this.label}...`);
      if (!this.selected) {
        this.$emit("click", data);
      }
    },
    async get_recursive(data) {
      if (this.childs.parent == 0) {
        this.childs.parent = data.code;
      }

      // console.log(
      //   `rendering childs recursively from parent ${data.description}...`
      // );

      const result = await this.postData(
        `https://tendersbackend.prettyneat.io/api/cpvfilter/cpvs/:parent`,
        {
          parent: data.code,
          includeAllChilds: true,
        }
      );
      const datac = result.data;

      for (let i = 0; i < datac.length; i++) {
        const el = datac[i];
        let obj = {};
        obj.label = el.description;
        obj.code = el.code;
        obj.cpvId = el.id;
        this.childs.children.push(obj);
      }

      return this.childs;
    },
    async add_recursive(data) {
      if (!this.selected) {
        if (!data.children) {
          this.add(data);
          data = await this.get_recursive(data);
        }
        // console.log(data);
        this.selected = true;
        this.$emit("dblclick", data);
      }
    },
  },
  computed: {
    indentation() {
      return { "margin-left": `${1 * 25}px` };
    },
  },
  created() {},
};
</script>

<style scoped>
li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
