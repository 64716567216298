import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/tailwind.css";
import VueRouter from "vue-router";
import router from "./router/router.js";
import Cookies from "./mixins/Cookies.js";

router.beforeEach(async (to, from, next) => {
  // Turn Cookies into key/value pairs
  let cookie_obj = Cookies.getCookieObject();

  if (
    (cookie_obj.access_token || cookie_obj.refresh_token) &&
    (to.path === "/login" || to.path === "/register")
  ) {
    next("/");
    // } else if (to.path === "/user" && !cookie_obj.logintype) {
    //   if (!cookie_obj.access_token && !cookie_obj.refresh_token) {
    //     next("/login");
    //   }
  } else {
    next();
  }
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueRouter);

new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
