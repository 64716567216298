<template>
  <div>
    <div class="details mt-5 flex flex-col gap-y-2">
      <span
        class="flex flex-row gap-x-2 gap-x-1 items-center"
        v-if="getCookieObj().logintype != 'google'"
      >
        <span class="w-60 block font-semibold">Username:</span>
        <span>{{ userdata.preferred_username }}</span>
      </span>
      <span class="flex flex-row gap-x-2 gap-x-1 items-center">
        <span class="w-60 block font-semibold">E-Mail:</span>
        <span>{{ email }}</span>
      </span>
      <span
        class="flex flex-row gap-x-2 gap-x-1 items-center"
        v-if="getCookieObj().logintype == 'keycloak'"
      >
        <span class="w-60 block font-semibold">E-Mail verified:</span>
        <span>{{ userdata.email_verified ? "Yes" : "No" }}</span>
      </span>
      <span
        class="flex flex-row gap-x-2 gap-x-1 items-center"
        v-if="getCookieObj().logintype != 'github'"
      >
        <span class="w-60 block font-semibold">First Name:</span>
        <span>{{ userdata.given_name }}</span>
      </span>
      <span
        class="flex flex-row gap-x-2 gap-x-1 items-center"
        v-if="getCookieObj().logintype != 'github'"
      >
        <span class="w-60 block font-semibold">Family Name:</span>
        <span>{{ userdata.family_name }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import TAjax from "../mixins/TAjax.vue";
import Cookies from "../mixins/Cookies.js";
import User from "../mixins/User.vue";
import CpvMainPanel from "../components/CpvMainPanel.vue";
import AdvancedSearch from "../components/AdvancedSearch.vue";

export default {
  name: "UserAccount",
  data() {
    return {
      userdata: {},
    };
  },
  components: { CpvMainPanel, AdvancedSearch },
  mixins: [TAjax, Cookies, User],
  methods: {
    getCookieObj() {
      return Cookies.getCookieObject();
    },
  },
  async created() {
    let data = await this.loadUser();

    // console.log("data");
    // console.log(data);

    if (!data) this.$router.push("/");

    this.userdata = data;
  },
  computed: {
    email() {
      return this.userdata.email ? this.userdata.email : "-";
    },
  },
};
</script>

<style></style>
