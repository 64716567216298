<script>
export default {
  name: "TAjax",
  methods: {
    async postData(url = "", data = {}) {
      // Default options are marked with *

      const response = await this.axios(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *client
        data, // body data type must match "Content-Type" header
      });
      return await response; // parses JSON response into native JavaScript objects
    },
    async getData(url = "", data = {}) {
      // Default options are marked with *

      const response = await this.axios(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *client
        data, // body data type must match "Content-Type" header
      });
      return await response; // parses JSON response into native JavaScript objects
    },
    async patchData(url = "", data = {}) {
      // Default options are marked with *

      const response = await this.axios(url, {
        method: "PATCH", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *client
        data, // body data type must match "Content-Type" header
      });
      return await response; // parses JSON response into native JavaScript objects
    },
    async putData(url = "", data = {}) {
      // Default options are marked with *

      const response = await this.axios(url, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *client
        data, // body data type must match "Content-Type" header
      });
      return await response; // parses JSON response into native JavaScript objects
    },
    async deleteData(url = "", data = {}) {
      const response = await this.axios(url, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *client
        data, // body data type must match "Content-Type" header
      });
      return await response; // parses JSON response into native JavaScript objects
    },
    parseResponseType(obj) {
      switch (obj.status) {
        case 200:
          return "Success";
        case 500:
          return "Error";
        case 400:
          return "More information needed";
        case 3:
          return "Other";
      }
      return "Unknown response type.";
    },
  },
};
</script>
